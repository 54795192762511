import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {UrlConstants} from '../../../../shared/helpers/url-constants';
import {Observable} from 'rxjs/index';
import {Zone} from '../../../../shared/models/db.model';
import {ProjectCustomUtils} from '../../../../shared/helpers/ProjectCustomUtils';

@Injectable({
    providedIn: 'root'
})
export class ZoneService {

    public zoneData: Zone[];

    constructor(private http: HttpClient) {
    }

    getItems(httpParams = new HttpParams()): Observable<any> {
        const url = UrlConstants.MAINURL + UrlConstants.zoneGetTableItems;
        return this.http.get<any>(url, {params: httpParams}).map(response => {
            this.zoneData = response.data;
            return response;
        }).share();
    }

    addZone(zone: Zone): Observable<Zone> {
        const url = UrlConstants.MAINURL + UrlConstants.zoneAdd;
        return this.http.post<Zone>(url, zone);
    }

    editZone(zone: Zone): Observable<Zone> {
        const url = UrlConstants.MAINURL + UrlConstants.zoneEdit;
        return this.http.post<Zone>(url, zone);
    }

    deleteZone(id: number): Observable<any> {
        const httpParams = new HttpParams().set('id', id.toString());
        const url = UrlConstants.MAINURL + UrlConstants.zoneDelete;
        return this.http.get(url, {params: httpParams});
    }

    IsSuspendedToggle(id: number, status: boolean) {
        const isSuspended = status ? 'true' : 'false';
        const httpParams = new HttpParams().set('id', id.toString()).set('isSuspended', isSuspended);
        const url = UrlConstants.MAINURL + UrlConstants.zoneIsSuspendedToggle;
        return this.http.get(url, {params: httpParams});
    }

    getAll(): Observable<Zone[]> {
        const url = UrlConstants.MAINURL + UrlConstants.zoneGetAll;
        const companyId = localStorage.getItem(ProjectCustomUtils.companyIdLocalStorageKey);
        const httpParams = new HttpParams().set('company', companyId.toString());
        return this.http.get<Zone[]>(url, {params: httpParams});

    }
}
