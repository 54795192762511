import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Group, PermissionItems} from '../../../../shared/models/db.model';
import {UrlConstants} from '../../../../shared/helpers/url-constants';
import {ProjectCustomUtils} from '../../../../shared/helpers/ProjectCustomUtils';

@Injectable({
    providedIn: 'root'
})
export class UserGroupService {

    constructor(private http: HttpClient) {
    }

    getItems(): Observable<Group[]> {
        const companyId = localStorage.getItem(ProjectCustomUtils.companyIdLocalStorageKey);
        const httpParams = new HttpParams().set('company_filter', companyId.toString());
        const url = UrlConstants.MAINURL + UrlConstants.userGroupGetAll;
        return this.http.get<Group[]>(url, {params: httpParams});

        // TODO: Implement the master data storage capability by using indexedDB
    }

    getUserGroupFeatureMatrix(groupId: number): Observable<PermissionItems[]> {
        const companyId = localStorage.getItem(ProjectCustomUtils.companyIdLocalStorageKey);
        const httpParams = new HttpParams().set('company', companyId.toString()).set('usergroup', groupId.toString());
        const url = UrlConstants.MAINURL + UrlConstants.userGroupGetFeaturePermissionItems;
        return this.http.get<PermissionItems[]>(url, {params: httpParams});

    }

    saveUserGroupFeaturePermissionList(permissionList: PermissionItems, usergroupId: number) {
        const url = UrlConstants.MAINURL + UrlConstants.userGroupSaveFeatureMatrix;
        const postBody = {
            feature: permissionList,
            usergroup: usergroupId
        };
        return this.http.post<PermissionItems[]>(url, postBody);
    }
}
