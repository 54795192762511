import { Injectable } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

import * as moment from 'moment';

import { DateAdapterService } from './date-adapter.service';

@Injectable()
export class CustomDateAdapter extends MomentDateAdapter
{
  constructor(private readonly dateAdapterService: DateAdapterService)
  {
    super(dateAdapterService.locale);
  }

  public format(date: moment.Moment, displayFormat: string): string
  {
    const locale = this.dateAdapterService.locale;
    const format = this.dateAdapterService.format;

    const result = date.locale(locale).format(format);

    return result;
  }
}